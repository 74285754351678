import store from '@/store'

export function getFormAddPsychologist() {
    return {
        buttons: [
            {
                textButton: 'Volver',
                color: 'secondary',
                event: 'back',
                style: 'margin: 10px',
                active: true
            },
            {
                textButton: 'Crear',
                color: 'success',
                event: 'add',
                style: 'margin: 10px',
                active: store.state.isAdmin
            },
        ],
        rows: [
            {
                style: '',
                class: '',
                content: [
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'name',
                            label: 'Nombre',
                            maxLength: 30,
                            specialCharactersAllowed: false,
                            allowSpaces: true,
                            error: ''
                        },
                    },
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'surname',
                            label: 'Apellidos',
                            maxLength: 30,
                            specialCharactersAllowed: false,
                            allowSpaces: true,
                            error: ''
                        },
                    },
                ]
            },
            {
              style: '',
              class: '',
              content: [
                {
                    type: 'element',
                    rule: [],
                    style: '',
                    class: '',
                    content: {
                        type: 'input',
                        value: '',
                        key: 'username',
                        label: 'Nombre de usuario',
                        maxLength: 30,
                        allowSpaces: false,
                        specialCharactersAllowed: true,
                        error: ''
                    },
                },
                {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'email',
                            label: 'Email',
                            maxLength: 30,
                            specialCharactersAllowed: true,
                            error: ''
                        },
                },
              ]
            },
            {
                style: '',
                class: '',
                content: [
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'password',
                            value: '',
                            key: 'password',
                            label: 'Contraseña',
                            maxLength: 30,
                            error: ''
                        },
                    },
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'password',
                            value: '',
                            key: 'repeatPassword',
                            label: 'Confirmar contraseña',
                            maxLength: 30,
                            error: ''
                        },
                    },
                ]
            },
        ],
    }
}

export function getFormEditPsychologist() {
    return {
        buttons: [
            {
                textButton: 'Cancelar',
                color: 'danger',
                event: 'cancel',
                style: 'margin: 10px',
                active: true
            },
            {
                textButton: 'Aceptar',
                color: 'success',
                event: 'edit',
                style: 'margin: 10px',
                active: store.state.isAdmin
            },
        ],
        rows: [
            {
                style: '',
                class: '',
                content: [
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'name',
                            label: 'Nombre',
                            maxLength: 30,
                            specialCharactersAllowed: false,
                            allowSpaces: true,
                            error: ''
                        },
                    },
                    {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'surname',
                            label: 'Apellidos',
                            maxLength: 30,
                            specialCharactersAllowed: false,
                            allowSpaces: true,
                            error: ''
                        },
                    },
                ]
            },
            {
              style: '',
              class: '',
              content: [
                {
                    type: 'element',
                    rule: [],
                    style: '',
                    class: '',
                    content: {
                        type: 'input',
                        value: '',
                        key: 'username',
                        label: 'Nombre de usuario',
                        maxLength: 30,
                        allowSpaces: false,
                        specialCharactersAllowed: true,
                        error: ''
                    },
                },
                {
                        type: 'element',
                        rule: [],
                        style: '',
                        class: '',
                        content: {
                            type: 'input',
                            value: '',
                            key: 'email',
                            label: 'Email',
                            maxLength: 30,
                            specialCharactersAllowed: true,
                            error: ''
                        },
                },
              ]
            },
        ],
    }
}