import config from "@/config";

import { get, del, post, patch } from "@/requests"

export async function getPsychologistsData() {
    return get(`${config.BACK_IP}/psychologists`)
}

export async function getPsychologistData(id) {
    return get(`${config.BACK_IP}/psychologist/${id}`)
}

export async function createNewPsychologist(newPsychologist) {
    return post(`${config.BACK_IP}/psychologist`, newPsychologist)
}

export async function updatePsychologist(psychologist, id) {
    return patch(`${config.BACK_IP}/psychologist/${id}`, psychologist)
}

export function deletePsychologist(id) {
    return del(`${config.BACK_IP}/psychologist/${id}`)
}

  
