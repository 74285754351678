<template>
    <div class="form">
        <div v-for="(row, rowIndex) in localFormContent.rows" :key="rowIndex">
            <CRow :class=row.class :style="row.style">
                <CCol v-for="(col, colIndex) in row.content" :key="colIndex" :class="col.class" :style="col.style">
                    <div v-if="col.type !== 'grid'">
                        <div v-for="(element, elementIndex) in col" :key="elementIndex">
                            <div v-if="element.type === 'input'">
                                <CInput :id="element.key" v-model="fields[element.key]" type="text" :label="element.label" horizontal :style="element.styles" :readonly="!canEdit && isAdmin" @input="validateInput(element)"/>
                                <div v-if="isInvalidInput(element)" class="text-danger-alert">
                                    <span>{{ element.error }}</span>
                                </div> 
                            </div>
                            <div v-if="element.type === 'input-number'">
                                <CInput :id="element.key" v-model="fields[element.key]" type="number" min="0" :label="element.label" horizontal :max="element.maxLength" :style="element.styles" :readonly="!canEdit && isAdmin" @input="validateInput(element)"/>
                                <div v-if="isInvalidInput(element)" class="text-danger-alert">
                                    <span>{{ element.error }}</span>
                                </div> 
                            </div>
                            <div v-if="element.type === 'password'">
                                <CInput v-if="element.type === 'password'" :id="element.key" v-model="fields[element.key]" type="password" :label="element.label" horizontal :readonly="!canEdit" @input="validateInput(element)"/>
                                <div v-if="isInvalidInput(element)" class="text-danger-alert">
                                    <span>{{ element.error }}</span>
                                </div> 
                            </div>
                            <div v-if="element.type === 'textArea'">
                                <CTextarea  :id="element.key" v-model="fields[element.key]" :label="element.label" horizontal :readonly="!canEdit && isAdmin" @input="validateInput(element)"/>
                                <div v-if="isInvalidInput(element)" class="text-danger-alert">
                                    <span>{{ element.error }}</span>
                                </div> 
                            </div>
                            <CInput v-if="element.type === 'date'" :id="element.key" v-model="fields[element.key]"
                                type="date" :label="element.label" horizontal :readonly="!canEdit && isAdmin"/>
                            <CInput v-if="element.type === 'file'" :id="element.key" v-model="fields[element.key]" accept=".jpg, .png, .webp, .svg" 
                                type="file" :label="element.label" horizontal :readonly="!canEdit && isAdmin" />
                            <CRow v-if="element.type === 'select'">
                                <CCol class="col-sm-3 align-middle" style="vertical-align: auto;">
                                    <h6>{{ element.label }}</h6>
                                </CCol>
                                <CCol class="col-sm-9 select-column">
                                    <select :id="element.key" v-model="fields[element.key]" :disabled="!canEdit && isAdmin">
                                        <option v-for="(option, optionIndex) in element.options" :key="optionIndex"
                                            :value="option.value">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </CCol>
                            </CRow>

                        </div>
                    </div>
                    <div v-if="col.type === 'grid'">
                        <AbstractForm :formContent="col.content" />
                    </div>
                </CCol>
            </CRow>
        </div>
        <div class="d-flex justify-content-center">
            <div v-for="(button, buttonIndex) in formContent.buttons" :key="buttonIndex">
                <CButton v-if="button.active != undefined ? button.active : true" :color="button.color" :class=button.class :style="button.style" @click="onEmit(button)">
                    {{ button.textButton }}
                </CButton>
            </div>
        </div>
    </div>
</template>

<script>
import AbstractForm from '@/components/Forms/AbstractForm.vue';

export default {
    name: 'AbstractForm',
    components: {
        AbstractForm,
    },
    data: () => ({
        localFormContent: null,
        isAdmin: null,
    }),
    props: {
        formContent: {
            type: Object,
            required: true,
        },
        fields: {
            type: Object,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
    },
    created() {
        this.isAdmin = this.$store.state.isAdmin
        this.localFormContent = this.formContent;
    },
    methods: {
        onEmit(button) {
            this.$emit('emit-methods', button.event, this.fields);
        },
        validateInput(element) {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const specialCharactersAllowed = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
            const specialCharactersDescriptionAllowed = /^[a-zA-Z0-9,.!¡¿?()\sáéíóúÁÉÍÓÚüÜñÑ]+$/;
            const fieldValue = this.fields[element.key];
            if (fieldValue === '') {
                element.error = element.type !== 'input-number' ? 'Este campo es obligatorio' : 'Este valor solo puede ser un número entero positivo';
            }else if(element.maxLength && fieldValue.length > element.maxLength) {
                element.error = `El número de caracteres de "${element.label}" debe ser menor o igual al siguiente valor: ${element.maxLength}`;
            }else if (!specialCharactersDescriptionAllowed.test(this.fields['desc'])) {
                element.error = 'La descripción solo acepta comas, puntos y los siguientes caracteres especiales: ?, ¿, !, ¡, ()';
            }else if (!element.allowSpaces && /\s/.test(fieldValue)) {
                element.error = 'Este campo no puede contener espacios';
            }else if(element.key === 'email' && !emailPattern.test(fieldValue)){
                element.error = 'Introduce un email válido.';
            }else if (element.key === 'repeatPassword' && fieldValue !== this.fields['password']) {
                element.error = 'Las contraseñas no son iguales';
            }else if(element.type === 'input-number'){
                if(fieldValue < 0 || specialCharactersAllowed.test(fieldValue)){
                    element.error = 'Este valor solo puede ser un número entero positivo'
                }else if(fieldValue > 2147483647){
                    element.error = 'El valor introducido debe ser menor o como máximo igual a 2147483647';
                }else{
                    element.error = '';
                }
            }else if (!element.specialCharactersAllowed && specialCharactersAllowed.test(fieldValue)) {
                element.error = 'El contenido introducido no debe tener caracteres especiales';
            }else {
                element.error = '';
            }
        },
        isInvalidInput(element) {
            return element && element.error !== undefined && element.error !== '';
        },
    }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .col {
    flex-basis: initial;
  }
  .select-column{
    margin-bottom: 5px;
    padding: 0 14px;
  }
}
</style>
